.dashboard-card{
    padding: 15px;
    color: white;
} 

.white-card{
    background: white;
}

.negative{
    color: black;
}

.dashboard-icon-container{
    width: 45px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: large;
    background: #ffffff61;
    margin-right: 15px;
}

.round-border{
    border-radius: 10px;
}

.dashboard-card-header{
    font-size: 14px;
    font-variant-caps: all-small-caps;
    line-height: 1.2;
    color: #ffffffaa;
}

.dashboard-card-value{
    font-size: 27px;
    line-height: 1.2;
    font-weight: 300;
}

.dashboard-container-header-container{
    padding: 10px;
}

.container-icon{
    font-size: medium;
    height: 30px;
    width: 30px;
    color: white;
    margin-right: 10px;
}

.dashboard-container-content-container{
    /* border: 1px solid red; */
    margin: 5px;
}

.container-title{
    color: black;
}

.container-title-date{
    color: rgba(0, 0, 0, 0.65);
}