.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.content-presenter{
  padding: 24px;
  background: #fff;
}

.white-page{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}

.flex-center{
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* ///////// */
.container{
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  position: relative;
  border-radius: 4px;
  padding: 10px;
}

.child{
  min-width: 120px;
  margin: 5px;
  padding: 0 15px 0 3px;
  border-radius: 3px;
  border: 1px solid;
  cursor: context-menu;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  color: black;
}

.filter-item-item{
  line-height: 1.3;
  font-weight: 600;
  font-size: small;
}

.filter-item-type{
  font-size: 10px;
  font-weight: normal;
  color: darkslategray;
}

.filter-item-icon{
  margin: 0 10px;
  font-size: 20px;
}


.active{
  border-width: 2px;
  border-color: cornflowerblue;
}

.canSubmit{
  border: 2px solid darkcyan;
  margin: 5px;
  padding: 10px;
}

.valid-check{
  /* height: 0; */
  background: #52c41a;
  /* width: 0; */
  display: inline-flex;
  position: absolute;
  top: -10px;
  left: -10px;
  padding: 5px;
  font-size: smaller;
  color: white;
  border-radius: 100%;
  border: 2px solid;
}

.artwork-card-title{
  font-size: 15px;

}

.artwork-cover-image{

}

.artwork-card-container{
  line-height: 1;
  /* max-height: 300px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.artwork-card-main{
  color: black;
  line-height: 2;
  font-weight: 600;
}

.artwork-card-date{
  font-weight: normal;
  font-size: 12px;
  color: darkblue;
}

.artwork-card-description{
  font-size: 12px;
  font-weight: normal;
  padding-top: 10px;
  line-height: 1.2;
}

.filter-breadcrumb{
  font-size: 12px;
  margin: 10px;
}

/* / */

.footer{
  height: 30px;
  background:#101010;
} 
.absolute{
  position: absolute;
}

.bottom{
  bottom: 0;
  left: 0;
  right: 0;
}

.content {
  color: #fff;
}
.center {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.small{
  font-size: 12px;
}
.solution-logo{
  width: 120px;
  height: 120px;
  /* background: antiquewhite; */
  /* margin-bottom: 100px; */
}

.custom-hr{
  /* margin: 70px 0; */
  color: #d0d0d0;
  border: none;
  width: 50px;
  border-bottom: 1px solid;
}

.login-button:focus{
  outline: 2px solid #cacaca;
}
.login-button{
  cursor: pointer;
}

.description{
  max-width: 330px;
  font-size: small;
}

.login-page-items{
  height: 380px;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.row-layout-style{
  border: 1px solid rgba(234, 234, 234) 
}

.row-layout-items{
  position: relative; 
  justify-content: space-between; 
}

.row-layout-claim-detail{
  border: 1px solid rgba(234, 234, 234) ;
  border-radius: 10px;
  margin : 6;
  padding:  8;
}

.container {
  position: relative;
  width: 100%;
  max-width: 100px;
  min-height: 100px;
  margin: 4px;
}

.container img {
  height: 100%;
  min-height: 100px;
  height: inherit;
}

.container .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #e62727;
  color: white;
  font-size: 14px;
  padding: 5px 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.container .btn:hover {
  background-color: black;
}

.box-button{  
  background:linear-gradient(rgb(149, 226, 6),transparent);
  background-color:#5d9101; /*this your primary color*/
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.box-button:hover {
  cursor: pointer;
}